import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3c2116",
        secondary: "#ffffff",
        accent: "#eecf67",
        error: "#d95a57",
        info: "#2196F3",
        success: "#76d897",
        warning: "#FFC107",
        secondary_low: "rgba(255,255,255,0.6)",
      },
      dark: {
        primary: "#1F2D32",
        secondary: "#576064",
        accent: "#FABD80",
        error: "#942929",
        info: "#13588d",
        success: "#398c3d",
        warning: "#aba204",
      },
    },
  },
});
