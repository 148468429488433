<template>
  <v-footer color="primary">
    <v-row class="d-flex justify-center align-center my-2">
      <v-col cols="12"
        ><a
          aria-label="phone number"
          href="tel:+420606581315"
          class="secondary--text"
        >
          +420 606 581 315
        </a></v-col
      >
      <v-col cols="12"
        ><a
          aria-label="email address"
          href="mailto:licek.josef@seznam.cz"
          class="secondary--text"
        >
          licek.josef@seznam.cz
        </a></v-col
      >
      <v-col cols="4" sm="2" lg="1">
        <v-row>
          <v-col>
            <a
              aria-label="instagram link"
              href="https://www.instagram.com/vinarstvi.josef.licek/"
            >
              <v-icon class="secondary--text">mdi-instagram</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="secondary--text mt-2" style="font-size: small">
        © 2020 - {{ $data.return.currentYear }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script lang="js">

export default {
  name: "AppFooter",
  computed: {
    theme() {
      return this.$vuetify.theme.currentTheme;
    },
  },
  data: () => ({
    return: {
      currentYear: new Date().getFullYear(),
    }
  })
};
</script>

<style scoped>
a {
  text-decoration: white;
}
</style>
