import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const DEAFULT_TITLE = "Vinařství Licek";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/domu",
  },
  {
    path: "/domu",
    name: "Domů",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/nabidka",
    name: "Nabídka",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OfferView.vue"),
  },
  {
    path: "/cenik",
    name: "Ceník",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PriceListView.vue"),
  },
  {
    path: "/galerie",
    name: "Galerie",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GalleryView.vue"),
  },
  {
    path: "/kontakty",
    name: "Kontakty",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactsView.vue"),
  },
  {
    path: "/nabidka/pro-bezny-den",
    name: "Pro běžný den",
    component: () => import("../views/OfferEverydayView.vue"),
  },
  {
    path: "/nabidka/neco-k-zamysleni",
    name: "Něco k zamyšlení",
    component: () => import("../views/OfferContemplationView.vue"),
  },
  {
    path: "/nabidka/slavnostni-prilezitost",
    name: "Pro slavnostní příležitost",
    component: () => import("../views/OfferFestiveView.vue"),
  },
  {
    path: "/nabidka/neco-neobvykleho",
    name: "Něco neobvyklého",
    component: () => import("../views/OfferExtraordinaryView.vue"),
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.name + " - " + DEAFULT_TITLE;
  });
  window.scrollTo({ top: 0, behavior: "smooth" });
});

export default router;
